@import "../variables";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.link {
  display: inline-block;
  color: $linkColor;
  padding: 10px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: $linkColor;
    border-radius: 3px;

    .linkInNewTabIcon {
      opacity: 0.5;
    }
  }
}

.linkInNewTabIcon {
  opacity: 0;
  float: right;
  margin-left: 8px;
  fill: $linkColor;
  height: 12px;
  width: 12px;

  @include mqMaxWidth($screenXs) {
    margin-left: 6px;
  }
}
