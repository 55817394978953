@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "variables.scss";

.container {
  position: fixed;
  overflow: hidden;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;

  @include mqMaxWidth($screenS) {
    position: relative;
  }
}

.content {
  position: relative;
  display: block;
  max-width: $screenL;
  margin: 0 auto;

  @include mqMaxWidth($screenL + 40px) {
    margin: 0 20px;
  }
}

.row {
  margin: 0 -10px;
  overflow: hidden;

  @include mqMaxWidth($screenS) {
    margin: 0;
    overflow: inherit;
  }
}

.pageContainerWithStickyFooter {
  position: relative;
  z-index: 1;
  margin-bottom: $pageContainerMarginBottom;
  min-height: 100%;
  min-height: 100vh;

  &.isSmall {
    margin-bottom: $smallPageContainerMarginBottom;

    @include mqMaxWidth($screenS) {
      margin-bottom: 0;
    }
  }

  @include mqMaxWidth($screenS) {
    margin-bottom: 0;
  }
}
