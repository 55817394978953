$contentHeight: 343px;
$smallContentHeight: 297px;
$navigationHeight: 63px;
$pageContainerMarginBottom: $contentHeight + $navigationHeight;
$smallPageContainerMarginBottom: $smallContentHeight + $navigationHeight;
$navigationSmallScreenHeight: 96px;

$navigationColor: #4c3269;
$linkColor: #fff;
$columnTitleColor: #bdbdbd;
$footerPanelArrowColor: #a8a1b1;
$footerPanelBorderBottomColor: #674b87;
