@import "@/Framework/GlobalStyles/variables";

.scheduleDemoModal {
  --legacy-schedule-demo-header-background-color: #6fcf97;
  --legacy-schedule-demo-header-clock-icon-color: #6fcf97;
  --legacy-schedule-demo-header-close-icon-color: #fff;
  --legacy-schedule-demo-header-title-color: #fff;
  --legacy-schedule-demo-header-title-tenant-color: #fff;

  @mixin buttonTenantStyles {
    :global {
      .modalBody > button {
        display: block;
        margin: 0 auto;
      }
    }
  }

  :global {
    .modalDialog {
      max-width: 610px;
      min-width: 375px;
      min-height: 878px;
    }

    .modalHeader {
      padding: 0;
    }

    .modalCloseBtn {
      fill: var(--legacy-schedule-demo-header-close-icon-color);
    }

    .modalFooter {
      padding-bottom: 30px;
    }
  }

  &:global(.tenant-17g5) {
    --legacy-schedule-demo-header-background-color: #bf4f6b;
    --legacy-schedule-demo-header-clock-icon-color: #bf4f6b;
    @include buttonTenantStyles;
  }

  &:global(.tenant-verisend) {
    --legacy-schedule-demo-header-background-color: #92929d;
    --legacy-schedule-demo-header-clock-icon-color: #92929d;
    @include buttonTenantStyles;
  }

  &:global(.tenant-allocate) {
    --legacy-schedule-demo-header-background-color: #0189b3;
    --legacy-schedule-demo-header-clock-icon-color: #0189b3;
    @include buttonTenantStyles;
  }

  &:global(.tenant-dealroadshow) {
    --legacy-schedule-demo-header-background-color: #5286d0;
    --legacy-schedule-demo-header-clock-icon-color: #5286d0;
    @include buttonTenantStyles;
  }

  &:global(.tenant-investorset) {
    --legacy-schedule-demo-header-background-color: #ffe484;
    --legacy-schedule-demo-header-clock-icon-color: #9a8356;
    --legacy-schedule-demo-header-close-icon-color: #616161;
    --legacy-schedule-demo-header-title-color: #2d2d2d;
    --legacy-schedule-demo-header-title-tenant-color: #2d2d2d;
    @include buttonTenantStyles;
  }

  &:global(.tenant-evercall) {
    --legacy-schedule-demo-header-background-color: #5acdbf;
    --legacy-schedule-demo-header-clock-icon-color: #5acdbf;
    @include buttonTenantStyles;
  }
}

.modalHeader {
  text-align: center;
  height: 176px;
  background: var(--legacy-schedule-demo-header-background-color);
  position: relative;
}

.tenantName {
  color: var(--legacy-schedule-demo-header-title-tenant-color);
  font-family: $fontFamily;
  line-height: 18px;
  font-size: 15px;
  margin: 0;
  padding-top: 5px;
}

.modalHeaderContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 40px;
  white-space: nowrap;
}

.modalHeaderTitle {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 36px;
  line-height: 39px;
  color: var(--legacy-schedule-demo-header-title-color);
  font-weight: bold;
}

.modalHeaderStamp {
  position: absolute;
  bottom: -37px;
  width: 100%;
  text-align: center;

  svg {
    fill: var(--legacy-schedule-demo-header-clock-icon-color);
  }
}

.modalContentContainer {
  margin-top: 67px;
}

.contentDescription {
  text-align: center;
}

.modalFooterContainer {
  text-align: center;
}
