@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "../variables.scss";

.navigation {
  padding: 15px 0 0;
  height: $navigationHeight;
  background-color: $navigationColor;
  position: relative;

  @include mqMaxWidth($screenS) {
    padding: 10px 0 0;
    height: $navigationSmallScreenHeight;
  }
}

.logo {
  display: none;
  @include mqMaxWidth($screenM) {
    display: block;
    top: inherit;
    bottom: 5px;
  }

  @include mqMaxWidth($screenS) {
    display: block;
    top: 40px;
    bottom: 0;
  }

  @include mqMaxWidth(375px) {
    top: 45px;

    svg {
      width: 150px;
    }
  }
}

.navigationLinks {
  display: inline-block;
  vertical-align: top;

  @include mqMaxWidth($screenM) {
    margin-left: 165px;
    padding-left: 25px;
  }

  @include mqMaxWidth($screenS) {
    display: block;
    margin-left: -10px;
    padding-left: 0;
    font-size: 12px;
  }

  @include mqMaxWidth(375px) {
    margin-left: 0;

    a {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

.iconsContainer {
  display: inline-block;
  float: right;

  @include mqMaxWidth($screenS) {
    margin-right: -10px;
  }
}

.icon {
  fill: #fff;
}

.flag {
  display: inline-block;
  vertical-align: top;
  padding: 10px;

  span {
    width: 22px;
    height: 16px;
    margin: 0;
  }

  @include mqMaxWidth($screenS) {
    margin-right: -10px;

    .language {
      display: none;
    }
  }
}

.language {
  display: inline-block;
  font-size: 12px;
  color: $linkColor;
  margin-left: 10px;
}
