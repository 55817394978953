@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.scheduleDemoForm {
  .halfWidth,
  .halfWidthDesktop,
  .fullWidth {
    &:nth-of-type(1),
    &:nth-of-type(4),
    &:nth-of-type(6) {
      padding-right: 15px;

      @include mqMaxWidth($screenS) {
        padding: 0;
      }
    }
  }

  .halfWidthDesktop {
    .halfWidth {
      &:nth-of-type(1) {
        padding-right: 7px;
      }

      &:nth-of-type(2) {
        padding-left: 7px;
      }
    }

    @include mqMaxWidth($screenS) {
      padding: 0;
    }
  }
}


.halfWidth {
  width: 50%;
  display: inline-block;
  float: left;
}

.halfWidthDesktop {
  @extend .halfWidth;

  @include mqMaxWidth($screenS) {
    width: 100%;
  }
}

.fullWidth {
  width: 100%;
  display: inline-block;
  float: left;
}

.detailTextareaWrp > div {
  margin-bottom: 20px;
}

.recaptchaWrp {
  height: 113px;
  text-align: center;
}

.timeAtSelect {
  width: 100%;
}

.submitBtnContainer {
  text-align: center;
}
