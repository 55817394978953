// Fonts
$titleFontFamily: "Open Sans", serif;
$fontFamily: Roboto;

$baseFontSize: 14px;
$baseFontSizeMobile: 12px;

$baseLineHeight: 18px;

// Titles
$h1FontSize: 36px;
$h1FontSizeMobile: 30px;

$h2FontSize: 24px;
$h2FontSizeMobile: 20px;
