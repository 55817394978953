@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.successContainer {
  width: 100%;
  text-align: center;
}

.title {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 39px;
  color: #6fcf97;
  margin-top: 85px;
}

.appointment {
  margin: 38px 30px 78px;
  background: #fff7de;
}

.appointmentContainer {
  padding: 30px 50px;
}

.appointmentTitle {
  color: #8c8c8c;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.appointmentDate {
  font-size: 24px;
  line-height: 33px;
  color: #2d2d2d;
  letter-spacing: -0.02em;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #dadada;
  margin: 20px 0;
}

.contactDetails {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #2d2d2d;
}

.description {
  font-family: $fontFamily;
  font-size: 15px;
  color: $baseTextColor;
  text-align: center;
  line-height: 18px;
  padding-bottom: 50px;
  max-width: 280px;
  margin: 0 auto;
}
