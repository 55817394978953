@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "../variables.scss";
@import "../stickyFooter.scss";

.container {
  padding: 30px 0;
  background-color: $firmColor;
  height: $contentHeight;
  position: relative;

  @include mqMaxWidth($screenS) {
    padding: 0;
    height: auto;
  }
}

.content {
  @extend .content;
  @include mqMaxWidth($screenS) {
    margin: 0;
  }
}

.logo {
  @include mqMaxWidth($screenM) {
    display: none;
  }
}

.linksContainer {
  vertical-align: top;
  display: inline-block;
  width: 78%;
  float: right;
  text-align: right;

  @include mqMaxWidth($screenM) {
    width: 100%;
  }

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.col {
  display: inline-block;
  width: 18.5%;
  vertical-align: top;
  text-align: left;

  &:last-child {
    width: 25%;
  }
}

.colTitle {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: $columnTitleColor;
  margin-bottom: 15px;
  letter-spacing: 0.1em;
  padding-left: 10px;
}

.item {
  color: #c0c0c0;
  font-size: 14px;

  a {
    max-width: 154px;
    width: 100%;
  }

  @include mqMaxWidth($screenS) {
    a {
      max-width: 100%;
    }

    &:last-child {
      padding-bottom: 10px;
    }
  }
}

.itemTextContainer {
  padding: 10px;
}

.footerPanelGroup {
  display: none;

  @include mqMaxWidth($screenS) {
    display: block;
  }
}

.footerPanel {
  position: relative;
  min-height: 45px;
  padding: 0 20px;
  margin-bottom: 0;
  border-bottom: 1px solid $footerPanelBorderBottomColor;
  -webkit-tap-highlight-color: transparent;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    border: 0;
  }
}

.footerPanelHeader {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: $linkColor;
  padding: 0;
  letter-spacing: 0.1em;
  height: 45px;
  background-color: transparent;
  display: flex;
  align-items: center;

  &:hover {
    i {
      fill: $linkColor;
    }
  }
}

.footerPanelContentWrapper {
  background-color: transparent;
  padding: 0;
}

.footerPanelArrow {
  display: inline-block;
  height: 10px;
  width: 10px;

  i {
    height: 10px;
    width: 10px;
    fill: $footerPanelArrowColor;
  }
  &:hover {
    background-color: transparent;
  }
  &.panelGroupHeaderArrowIcon:hover {
    i {
      fill: $footerPanelArrowColor;
    }
  }
}
