@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.cookiesBannerWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgb(45, 45, 45);
  z-index: 10;
  padding: 20px 40px;
  opacity: 0.9;

  @include mqMaxWidth($screenM) {
    padding: 20px;
  }
  @include mqMaxWidth($screenS) {
    padding: 32px 20px 20px;
  }

  .cookiesBannerClose {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 12px;
    height: 12px;
    fill: #fff;
  }

  .cookiesBannerContent {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mqMaxWidth($screenM) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .cookiesBannerBtn {
    margin: 0 0 0 20px;
    line-height: 28px;
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 9px;

    @include mqMaxWidth($screenM) {
      margin: 10px 0 0;
    }
  }

  .cookiesBannerManageBtn {
    @extend .cookiesBannerBtn;
    margin: 0;
    @include mqMaxWidth($screenM) {
      margin: 0;
    }
  }

  .cookiesManageBlock {
    margin: 0 0 0 20px;
    display: flex;
    @include mqMaxWidth($screenM) {
      margin: 10px 0 0;
    }
  }

  .cookiesCheckboxes {
    display: flex;
  }

  .functionalCheckbox {
    opacity: 0.5;
  }

  .marketingCheckbox {
    margin-left: 20px;
  }
  .marketingLabel {
    color: #fff;
  }

  .cookiesBannerText {
    font-size: 14px;
    line-height: 20px;
    max-width: 670px;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;

    @include mqMaxWidth($screenM) {
      text-align: center;
      line-height: 18px;
    }
    @include mqMaxWidth($screenS) {
      font-size: 13px;
    }
  }

  .cookiesBannerLink {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: underline;
    white-space: nowrap;
  }
}
