@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "../variables.scss";

.logo {
  fill: $linkColor;
  position: absolute;
  top: 0;
  left: 0;
}
